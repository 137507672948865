import cn from "classnames";
import React from "react";
import ChevronSVG from "../../images/chevron-right.inline.svg";
import styles from "./styles.module.css";

const ChevronRight = ({ height = 10, black = false }) => (
  <ChevronSVG height={height} className={cn(styles.svg, black ? styles.black : styles.white)}/>
);

export default ChevronRight;
