import cn from "classnames";
import React, { useRef } from "react";
import HowItWorks from "../HowItWorks";
import Pattern from "./Pattern";
import styles from "./styles.module.scss";

const Header = ({ title, subtitle }) => {
  const titleRef = useRef();
  const howItWorksRef = useRef();

  return (
    <header className={styles.wrap}>
      <Pattern className={styles.position} refs={[titleRef, howItWorksRef]}/>

      <div className="container-fluid container-xl">
        <div className={cn(styles.position, "position-relative")}>
          <div className={styles.center}>
            <div ref={titleRef} className={styles.titleWrap}>
              <h1 className={cn(styles.title, "type-42")}>
                {title}
              </h1>

              {subtitle && (
                <span className={cn(styles.subtitle, "type-24")}>
                  {subtitle}
                </span>
              )}
            </div>

            <HowItWorks ref={howItWorksRef}/>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
