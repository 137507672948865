import cn from "classnames";
import React from "react";
import { cookieLink, privacyLink } from "../../data/footer.yaml";
import styles from "./styles.module.scss";

const Footer = () => (
  <footer>
    <div className="container-fluid container-xl">
      <div className="border-top">
        <nav className={cn("navbar navbar-expand", styles.navbar)}>
          <span className="navbar-text">
            <small>
              <strong className="d-block type-16">
                Are you recruiting? Call the team on 0333 0145 111
              </strong>
              &copy; Totaljobs Group Ltd 2020
            </small>
          </span>

          <ul className="navbar-nav ml-auto">
            <li className={cn("nav-item", styles.footerNavItem)}>
              <a href={privacyLink} className={cn("nav-link", styles.footerLink)}>
                <small>
                  Privacy Policy
                </small>
              </a>
            </li>
            <li className={cn("nav-item", styles.footerNavItem)}>
              <a href={cookieLink} className={cn("nav-link", styles.footerLink)}>
                <small>
                  Cookies
                </small>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </footer>
);

export default Footer;
