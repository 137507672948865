import PropTypes from "prop-types";
import React from "react";
import NavBar from "../NavBar";

const Layout = ({ children }) => (
  <>
    <a className="sr-only" href="#content">
      Skip to content
    </a>
    <NavBar/>
    <main id="content">
      {children}
    </main>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
