import cn from "classnames";
import React, { useState } from "react";
import NavItem from "./NavItem";
import styles from "./styles.module.scss";

const NavBar = () => {
  const [open, setOpen] = useState(false);

  return (
    <nav className={cn("navbar navbar-header navbar-expand-md", styles.navBar)} aria-label="main-nav">

      <div className="container">
        <a title="Totaljobs" href="/" className={styles.logo}>
          <span className="sr-only">
            Totaljobs
          </span>
        </a>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                onClick={() => setOpen(!open)}
        >
          <span className={styles.bar}/>
          <span className={styles.bar}/>
          <span className={styles.bar}/>
        </button>

        <div className={cn("collapse navbar-collapse", { "show": open })} id="navbarSupportedContent">
          <ul className={cn(styles.nav, "navbar-nav mr-auto")}>
            <NavItem to="/">
              Decoder
            </NavItem>
            <NavItem to="/insights/">
              Insights
            </NavItem>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
