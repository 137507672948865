import React from "react";
import Shapes from "./shape-separator.inline.svg";
import styles from "./styles.module.css";

const ShapeSeparator = ({ width = 87 }) => (
  <div className={styles.wrap}>
    <Shapes width={width}/>
  </div>
);

export default ShapeSeparator;
