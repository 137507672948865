import cn from "classnames";
import React from "react";
import ChevronRight from "../ChevronRight";
import styles from "./styles.module.scss";

const DiscoverLink = ({ className }) => (
  <a href="https://www.totaljobs.com/insidejob/gender-pay-trap" className={cn(styles.link, className)} target="_blank" rel="noopener noreferrer">
    <span className={cn(styles.text, "type-16")}>
      Discover more about the Gender Pay Trap
      <ChevronRight/>
    </span>
  </a>
);

export default DiscoverLink;
