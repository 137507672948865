import cn from "classnames";
import React from "react";
import styles from "./styles.module.scss";

const MasculineHighlight = ({ bold = false, className, children = "male-coded" }) => (
  <span className={cn(styles.highlight, styles.masculine, className, { [styles.bold]: bold })}>
    {children}
  </span>
);

export default MasculineHighlight;
