import cn from "classnames";
import React, { forwardRef, useState } from "react";
import Modal from "react-modal";
import CloseIcon from "../CloseIcon";
import ShapeSeparator from "../ShapeSeparator";
import styles from "./styles.module.scss";
import {Link} from "gatsby";

Modal.setAppElement(`#___gatsby`);

const HowItWorks = forwardRef((__, ref) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const openModal = () => {

    const header = document.getElementsByClassName("navbar-header")[0];
    const { height, y } = header.getBoundingClientRect();

    setScrollTop(Math.min(height, Math.abs(y)));
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setOpened(false);
  };

  const onAfterOpen = () => {
    setOpened(true);
  };

  return (
    <>
      <button className={cn(styles.button, "type-16")} onClick={openModal} ref={ref}>
        How it works
      </button>

      <Modal
        overlayClassName={cn(styles.modalOverlay, { [styles.opened]: opened })}
        style={{
          overlay: {
            transform: `translateY(-${scrollTop}px)`,
            height: `calc(100vh + ${scrollTop}px)`
          }
        }}
        className={styles.modal}
        isOpen={modalIsOpen}
        onAfterOpen={onAfterOpen}
        closeTimeoutMS={500}
      >

        <div className="position-relative">
          <button onClick={closeModal} className={styles.closeButton}>
            <CloseIcon size={50}/>
          </button>

          <div className="container">
            <div className="row justify-content-center">
              <div className={cn("col col-xl-7", styles.padding)}>

                <ShapeSeparator/>

                <h3 className={cn(styles.title, "type-42")}>
                  How it works
                </h3>
                <p className="type-20">
                  We created the Totaljobs Gender Bias Decoder using academic research into gender bias in job adverts.
                </p>
                <p className="type-20">
                  A <a href="http://www.fortefoundation.org/site/DocServer/gendered_wording_JPSP.pdf?docID=16121"
                       target="_blank" rel="noopener noreferrer">2011 study by the University
                  of Waterloo and Duke University</a> found that job adverts for roles in male-dominated industries used
                  more male-coded words. And this biased wording led to fewer women applying. Male-coded words are those
                  associated with societal ideas of male stereotypes and female-coded words are those associated with
                  female stereotypes.
                </p>
                <p className="type-20">
                  We don’t believe that 'lead' is a male trait any more than 'supportive' is a female one. So, we
                  created this tool to raise awareness and inspire positive change.
                </p>
                <p className="type-20">
                  The Gender Bias Decoder uses the words identified by researchers and highlights them for you. To
                  remove bias in your text, try to reduce the number of gendered words.
                </p>
                <p className="type-20">
                  That's not all the Gender Bias Decoder does. At Totaljobs, we care about diversity, so we used it to
                  look at job adverts published on our own website.
                </p>
                <p className="type-20">
                  See what we discovered when we put
                  almost <Link to="/insights/">350,000 adverts through our Decoder</Link>, and find out how it
                  relates to the <a href="https://www.totaljobs.com/insidejob/gender-pay-trap" target="_blank" rel="noopener noreferrer">Gender Pay
                  Trap</a> that’s costing women thousands of pounds each year.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default HowItWorks;
