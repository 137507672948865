import cn from "classnames";
import forEach from "lodash/forEach";
import throttle from "lodash/throttle";
import times from "lodash/times";
import React, { useEffect } from "react";
import HeaderDefsSVG from "../../../images/header.defs.inline.svg";
import HeaderSVG from "../../../images/header.inline.svg";
import styles from "./styles.module.scss";

const numberOfSVGs = 20;
const padding = 20;
const fadeClass = "fading";

const Pattern = ({ refs, className }) => {
  useEffect(() => {
    const svgs = document.getElementsByClassName("header-svg-group");

    const onResize = throttle(() => {

      forEach(svgs, (icon) => {
        icon.classList.remove(fadeClass);
      });

      refs.forEach((ref) => {
        const { current } = ref;

        if (current) {
          const currentRect = current.getBoundingClientRect();

          forEach(svgs, (icon) => {
            const iconRect = icon.getBoundingClientRect();

            if (
              (currentRect.x - padding) < (iconRect.x + iconRect.width) &&
              (currentRect.x + currentRect.width + padding) > iconRect.x
            ) {
              icon.classList.add(fadeClass);
            }
          });
        }
      });
    }, 200);

    window.addEventListener("resize", onResize);

    onResize();

    return () => window.removeEventListener("resize", onResize);
  }, [refs]);

  return (
    <div className={cn(styles.pattern, className)}>
      <HeaderDefsSVG className={styles.hide}/>
      {times(numberOfSVGs).map((__, index) => (
        <HeaderSVG key={index}/>
      ))}
    </div>
  );

};

export default Pattern;
