import React from "react";

const Sources = ({ children }) => (
  <div className="pt-4">
    {children && (
      <div className="pb-4">
        {children}
      </div>
    )}
    <div className="pb-4">
      <p>
        <small>
          This tool is adapted from Kat Matfield's Gender Decoder for Job Ads based on: Danielle Gaucher, Justin
          Friesen, and <span className="text-nowrap">Aaron C. Kay</span> (2011), Evidence That Gendered Wording in
          Job Advertisements Exists and Sustains
          Gender Inequality (Journal of Personality and Social Psychology, July 2011, Vol 101(1), p109-28).
        </small>
      </p>
    </div>
  </div>
);

export default Sources;
